import React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'

import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { RichText } from 'prismic-reactjs'
import { IndexPageQuery } from '../../types/graphql-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
import htmlSerializer from '../utils/htmlSerializer'
import { Body } from '../slices/Slice'

const IndexPage = () => {
  const data = useStaticQuery<IndexPageQuery>(graphql`
    query IndexPage {
      allPrismicPage(filter: { tags: { eq: "index" } }) {
        edges {
          node {
            data {
              text {
                raw
              }
              seodescription {
                text
              }
              seotitle {
                text
              }
              headerimage {
                fluid(maxWidth: 1000) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
              title {
                raw
              }
              body {
                ... on PrismicPageBodyTable3 {
                  id
                  slice_type
                  items {
                    col2 {
                      raw
                    }
                    col1 {
                      raw
                    }
                    col3 {
                      raw
                    }
                  }
                }
                ... on PrismicPageBodyTable2 {
                  id
                  slice_type
                  items {
                    col1 {
                      raw
                    }
                    col2 {
                      raw
                    }
                  }
                }
                ... on PrismicPageBodyText {
                  id
                  primary {
                    text {
                      raw
                    }
                  }
                  slice_type
                }
              }
            }
          }
        }
      }
    }
  `)
  if (!data) {
    return null
  }
  // @ts-ignore
  const node = data.allPrismicPage.edges[0].node
  return (
    <Layout>
      <SEO title={node.data.seotitle.text} description={node.data.seodescription.text} lang="de" />
      <Img fluid={node.data.headerimage.fluid} />
      <Heading fontFamily="montserrat" marginTop="3rem" color="#67211D">
        {node.data.title.raw[0].text}
      </Heading>

      <Box fontFamily="merriweather" color="#151515">
        {node.data.text.raw && <RichText htmlSerializer={htmlSerializer} render={node.data.text.raw} />}
      </Box>
    </Layout>
  )
}

export default IndexPage
